import type { HTMLProps } from "react";
import type { TravelPost } from "../data/travelPosts";
import { TravelPolaroid } from "./TravelPolaroid";

export function TravelPolaroidStack({
	posts,
	...rest
}: {
	posts: [TravelPost, TravelPost, TravelPost];
} & HTMLProps<HTMLDivElement>) {
	return (
		<div {...rest} className="relative">
			{/* Left */}
			<div className="absolute hocus:z-20 w-[40%]">
				<TravelPolaroid
					key={posts[0].randomImage.src.medium}
					title={posts[0].town}
					image={posts[0].randomImage}
					to={posts[0].pathname}
					randomSeed={8}
				/>
			</div>
			{/* Right */}
			<div className="absolute left-[60%] hocus:z-20 w-[40%]">
				<TravelPolaroid
					key={posts[2].randomImage.src.medium}
					title={posts[2].town}
					image={posts[2].randomImage}
					to={posts[2].pathname}
					randomSeed={5}
				/>
			</div>
			{/* Centre */}
			<div className="relative left-[30%] hocus:z-20 z-10 w-[40%]">
				<TravelPolaroid
					key={posts[1].randomImage.src.medium}
					title={posts[1].town}
					image={posts[1].randomImage}
					to={posts[1].pathname}
					randomSeed={6}
				/>
			</div>
		</div>
	);
}
